.image-mosaic {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: 200px;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #353535;
  font-size: 2rem;
  color: #fff;
  box-shadow: rgba(3, 8, 20, 0.1) 0px 0.15rem 0.5rem, rgba(2, 8, 20, 0.1) 0px 0.075rem 0.175rem;
  /*height: 100%;*/
  /*width: 100%;*/
  border-radius: 8px;
  transition: all 500ms;
  /*overflow: hidden;*/
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
  opacity: 1.0;
  /*object-fit: contain;*/
}

.card:hover {
  opacity: 1.0;
}

@media screen and (min-width: 600px) {
  .card-tall {
    grid-row: span 2 / auto;
  }

  .card-wide {
    grid-column: span 2 / auto;
  }
}
